.opacity_bg {
  &:after {
    background: linear-gradient(
      270deg,
      #ffffff 18.23%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  &:before {
    background: linear-gradient(
      90deg,
      #ffffff 18.23%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
